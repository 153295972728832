exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-js": () => import("./../../../src/pages/accommodation.js" /* webpackChunkName: "component---src-pages-accommodation-js" */),
  "component---src-pages-additional-info-js": () => import("./../../../src/pages/additional-info.js" /* webpackChunkName: "component---src-pages-additional-info-js" */),
  "component---src-pages-contact-faq-js": () => import("./../../../src/pages/contact-faq.js" /* webpackChunkName: "component---src-pages-contact-faq-js" */),
  "component---src-pages-dance-classes-js": () => import("./../../../src/pages/dance-classes.js" /* webpackChunkName: "component---src-pages-dance-classes-js" */),
  "component---src-pages-evening-dances-js": () => import("./../../../src/pages/evening-dances.js" /* webpackChunkName: "component---src-pages-evening-dances-js" */),
  "component---src-pages-how-to-get-there-js": () => import("./../../../src/pages/how-to-get-there.js" /* webpackChunkName: "component---src-pages-how-to-get-there-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-spring-fringe-js": () => import("./../../../src/pages/spring-fringe.js" /* webpackChunkName: "component---src-pages-spring-fringe-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

